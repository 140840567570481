import { format as dateFormat } from "date-fns";
import { ru } from "date-fns/locale";

function dateFormatHuman(d, w = null) {
  // return "19 окт" or "19 окт, чт"
  let date_in;
  try {
    date_in = new Date(d);
  } catch {
    console.log("seems like wrong date", d);
    date_in = new Date();
  }
  const dayMonth = new Intl.DateTimeFormat("ru-RU", { day: "numeric", month: "short" })
    .format(date_in)
    .replace(".", "");
  const weekShort = new Intl.DateTimeFormat("ru-RU", { weekday: "short" }).format(date_in);
  let res = dayMonth;
  if (w) {
    res = dayMonth + ", " + weekShort;
  }
  return res;
}

function dateFormatVarious(d, format = 1, formatObject) {
  // return "30.10.2023" or "30.10.2023 (пн)"
  let date_in;
  try {
    date_in = new Date(d);
  } catch {
    console.log("seems like wrong date", d);
    date_in = new Date();
  }

  if (formatObject) {
    return date_in.toLocaleDateString("ru", formatObject);
  }

  const YYYY = date_in.getFullYear();
  const MM = (date_in.getMonth() + 1 < 10 ? "0" : "") + (date_in.getMonth() + 1);
  const DD = (date_in.getDate() < 10 ? "0" : "") + date_in.getDate();
  const WW = new Intl.DateTimeFormat("ru-RU", { weekday: "short" }).format(date_in);

  let res = DD + "." + MM + "." + YYYY;
  switch (format) {
    case 1:
      return res;
    case 2:
      return res + " (" + WW + ")";
    default:
      return res;
  }
}

function dateFormatJS(d = null) {
  const date_in = new Date(d);
  // return "2023-10-30"
  // format date_in to ISO 8601 Extended, DATE ONLY
  const YYYY = date_in.getFullYear();
  const MM = (date_in.getMonth() + 1 < 10 ? "0" : "") + (date_in.getMonth() + 1);
  const DD = (date_in.getDate() < 10 ? "0" : "") + date_in.getDate();
  return YYYY + "-" + MM + "-" + DD;
}

function pluralYears(n) {
  // return "год" or "года" or "лет"
  const declension = ["год", "года", "лет"];
  const cases = [2, 0, 1, 1, 1, 2];
  return declension[n % 100 > 4 && n % 100 < 20 ? 2 : cases[n % 10 < 5 ? n % 10 : 5]];
}

function formattedDate(date) {
  let dayOfWeek = dateFormat(new Date(date), "EEEEEE", { locale: ru });
  dayOfWeek = dayOfWeek.substring(0, 1).toUpperCase() + dayOfWeek.substring(1);
  const dateFormatted = dateFormat(new Date(date), "dd.MM", { locale: ru });
  return { dayOfWeek, dateFormatted };
}

export { dateFormat, dateFormatHuman, dateFormatJS, dateFormatVarious, pluralYears, formattedDate };
